/* https://material.io/tools/color/#!/?view.left=0&view.right=0&primary.color=4FC3F7&secondary.color=FF7043&primary.text.color=000000 */

$widthMaxContainer: 1012px;

@font-face {
   font-family: 'Caveat-Regular';
   src: local('Caveat-Regular'),
      url(./fonts/Caveat-Regular.ttf) format('truetype');
   font-display: swap;
}

@font-face {
   font-family: 'Caveat-Bold';
   src: local('Caveat-Bold'), url(./fonts/Caveat-Bold.ttf) format('truetype');
}

html {
   overflow-y: scroll;
}

html,
#root {
   background-color: #fff;
}

.containerMarginTop10 {
   margin-top: 10px;
}

.paddingSize10 {
   padding: 0 10px;
}

.MuiContainer-root {
   max-width: $widthMaxContainer;
   padding-left: 24px;
   padding-right: 24px;
}

.MuiGrid-root {
   text-align: initial;
   position: relative;
}

// pour compenser le padding-left par défaut du composant switch
.marginLeftSwitchComponent {
   margin-left: -12px;
}

// .MuiPaper-root {
//    padding: 25px;
//    margin-bottom: 10px;
//    color: rgb(85, 85, 85);
// }
.MuiAlert-filledError,
.MuiAlert-filledSuccess,
.MuiAlert-filledInfo,
.MuiAlert-filledWarning {
   color: #fff;
}
// Correction du padding sur les petits élements utilisant Paper
// Snackbar
.MuiAlert-root {
   padding: 10px;
}

.button-with-loading {
   margin-top: 10px;
}

.narrowPaper {
   margin: auto;
   max-width: 500px;
}

.MuiMenuItem-root {
   min-height: 32px;
}

.MuiCardHeader-root {
   padding: 0 0 15px 0;
}

.MuiFormControl-root {
   min-width: 100%;
   margin-bottom: 15px;
}

.footer {
   //max-width: $widthMaxContainer;
   margin-bottom: 0;
   padding-bottom: 0;
}

.footer {
   .MuiGrid-root {
      text-align: center;
   }
   .MuiDivider-root {
      margin-top: 10px;
   }
}

.nav-link {
   padding: 0;
}

a {
   text-decoration: none;
}

.MuiTypography-displayBlock {
   display: block;
}

.MuiTypography-displayBlock {
   display: block;
}

//.MuiTypography-root {
//   margin: 0;
//}

*,
::before,
::after {
   box-sizing: inherit;
}

// https://material-ui.com/customization/typography/#responsive-font-sizes
// .MuiTypography-h1 {
//    font-size: 3.25rem;
// }

// .MuiTypography-h2 {
//    font-size: 2.75rem;
// }

// .MuiTypography-h3 {
//    font-size: 2rem;
//    padding: 0 0 15px 0;
// }

// .MuiTypography-h4 {
//    font-size: 1.5rem;
// }

// .MuiTypography-h5 {
//    font-size: 1.5rem;
//    font-family: 'Lato', sans-serif;
//    font-weight: 400;
//    line-height: 1.334;
// }

.terms {
   line-height: 2;
}

div.containerAlignIconText {
   display: flex;
   align-items: center;
   flex-wrap: wrap;

   .spaceIcon {
      margin-right: 5px;
   }
}

/*
    Responsive
 */
@media screen and (max-width: 767px) {
   // Sur petit écran on ne réduit pas les bords pour maximiser l'espace
   .MuiContainer-root {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
   }
   // Sur la page terms, on réduit le padding des listes
   ol.terms {
      padding-left: 10px;
   }
   ul {
      padding-left: 10px;
   }
}
