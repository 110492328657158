/*
    Grand écran
 */
//On cache le menu drawer
.mobile-menu {
   display: none;
}

header {
   padding: 0 10px !important;
   margin-bottom: 0 !important;
}

//Position absolue pour le logo afin de centrer le menu
.logo {
   position: absolute;
   left: 0;
}
.logo-mobile {
   display: none;
   position: absolute;
   left: 0;
}

//On cache l'icône MenuIcon
.mobile-menu-icon {
   display: none;
}

.desktop-menu {
   margin: auto;
}

//On centre les éléments qui forment la barre du haut
// .justify-content {
//    justify-content: center;
// }
//Position absolue pour que le menu se centre correctement
.button-logout-desktop {
   position: absolute;
   right: 0;
}

/*
    Moyen-Grand écran
 */
@media screen and (max-width: 1300px) {
   .logo {
      display: none;
   }
   .logo-mobile {
      display: block;
   }
}

/*
    Moyen écran
 */
@media screen and (max-width: 1000px) {
   //On affiche l'écran mobile
   .mobile-menu {
      display: block;
   }
   //On affiche l'icône MenuIcon
   .mobile-menu-icon {
      display: block;
      float: right;
   }
   //On sépare à gauche le logo et à droite le MenuIcon
   .justify-content {
      justify-content: space-between;
   }
   //On cache le menu grand écran
   .desktop-menu {
      display: none;
   }
   //On cache le bouton déconnexion grand écran
   .button-logout-desktop {
      display: none;
   }
}
